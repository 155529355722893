<template>
  <b-card>
    <b-form @submit.prevent>
       <b-row class="d-flex align-items-top justify-content-center">

         <b-col class="col-sm-12 col-md-4 col-lg-4 pb-2">
          <div class="border-bottom border-left border-right d-block border-bottom-effect p-2 shadow rounded">
            <b-col cols="12" class="d-flex justify-content-center align-items-center product-img-side">
              <b-row>
                  <b-media class="mb-2 css-reset">
                  <b-img ref="previewEl" :src="userImg" class="product-img my-2" center />
                </b-media>
              </b-row>
            </b-col>
         <b-row class="d-flex justify-content-center align-items-center">
            <b-button variant="primary upload-button" @click="toggleShow">
                <span class="d-none d-sm-inline">
                    <b-icon-capslock-fill class="up-icon" />
                    <span class="up-text">{{ $t("Upload") }}</span>
                </span>
                <b-icon-capslock-fill class="up-icon d-inline d-sm-none" />
                  </b-button>
                  <my-upload field="img" @crop-success="cropSuccess" v-model="show" :width="150" :height="150" langType="tr" :params="params"
                    :headers="headers"
                    img-format="png"></my-upload>
              <b-button
                variant="danger remove-button"
                class="ml-1"
                @click="removePhoto">
                <span class="d-none d-sm-inline">
                  <b-icon-trash class="remove-icon" />
                  <span class="remove-text">{{ $t("Remove") }}</span>
                </span>
                <b-icon-trash class="remove-icon d-inline d-sm-none" />
              </b-button>

         </b-row>
          </div>
        </b-col>

        <b-col class="col-lg-8 col-md-8 col-sm-12">
          <div class="border-bottom border-left border-right d-block border-bottom-effect p-1 shadow rounded">
          <!-- ürün başlığı -->
            <b-form-group :label="$t('Product Title')" label-for="product_title">
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ItalicIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="product_title"
                      name="product_title"
                      type="text"
                      :placeholder="$t('Product Title')"
                      v-model="product_data.product_title"
                      @blur="$v.product_data.product_title.$touch()"/>

                  </b-input-group>
                  <small v-if="isSubmit && !$v.product_data.product_title.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
            </b-form-group>
            <!-- activite adı -->
            <b-form-group :label="$t('Activity')" label-for="activity">
                <v-select
                  style="width: 100%; z-index: 400; display: inline-block"
                  :reduce="x => x.activity_id"
                  v-model="selectedActivity.activity_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="activities"
                  value="activity_id"
                  label="name"
                  name="activity"
                  @blur="$v.selectedActivity.activity_id.$touch()"
                >
                </v-select>
                <small v-if="isSubmit && !$v.selectedActivity.idCheck" class="form-text text-danger" >{{$t("This field is required")}}</small>
            </b-form-group>
            <!-- ürün açıklaması -->
            <b-form-group :label="$t('Product Description')" label-for="product-description">
                <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                    <feather-icon icon="ItalicIcon" />
                  </b-input-group-prepend>
                <b-form-textarea v-model="product_data.product_description"
                    id="product_description" name="product_description"
                    :placeholder="$t('Product Description')" rows="2" @blur="$v.product_data.product_description.$touch()"/>
                </b-input-group>
                  <small v-if="isSubmit && !$v.product_data.product_description.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
            </b-form-group>

          </div>
        </b-col>
         <div class="container-fluid">
         <div class="row p-0 mt-0">     
           <div class="col-md-12">
             <b-form-group>
               <div class="border-bottom border-left border-right d-block border-bottom-effect pl-1 pb-1 pt-1 pr-1 shadow rounded">
                 <label for="scheduler-type" class="d-block">
                   <p>{{$t('The Number Of Participants')}}</p>
                   <hr>
                 </label>
                 <b-input-group class="input-group-merge">
                   <b-input-group-prepend is-text>
                     <feather-icon icon="UserIcon" />
                   </b-input-group-prepend>
                   <b-form-input id="participants_count" name="participants_count" type="number"
                       :placeholder="$t('The Number Of Participants')" v-model="product_data.participants_count"
                       @blur="$v.product_data.participants_count.$touch()"/>
                 </b-input-group>
               </div>
               <small v-if="isSubmit && !$v.product_data.participants_count.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
             </b-form-group>
              <b-form-group label-for="scheduler">
               <div class="border-bottom border-left border-right d-block border-bottom-effect pl-1 pb-1 pt-1 shadow rounded">
                 <label for="price-type" class="d-block"> <p>{{ $t('Geçerlilik Süresi') }}<info-icon v-b-modal.modal-2 size="1.25x" class="custom-class"> </info-icon></p><hr/>
                 </label>
                 <div class="row pr-1">
                   <div class="col-md-6 col-sm-12">
                     <label for="">{{$t('Month')}}</label>
                     <v-select
                         style="width: 100%; z-index: 400; display: inline-block"
                         :reduce="(x) => x.value"
                         v-model="month"
                         :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                         :options="optionsMonth"
                         value="value"
                         label="text"
                         name="month"
                         :clearable="false"
                         :disabled="isMonthDisabled"
                         @blur="$v.month.$touch()"
                     >
                     </v-select>
                     <small
                         v-if="!$v.month.check && isSubmit"
                         class="form-text text-danger"
                     >
                       {{$t('The Validity Period Month Selection Is A Required Field')}}
                     </small>
                   </div>
                   <div class="col-md-6 col-sm-12">
                     <label for="">{{$t('Day')}}</label>
                     <v-select
                         style="width: 100%; z-index: 400; display: inline-block"
                         :reduce="(x) => x.value"
                         v-model="day"
                         :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                         :options="optionsDay"
                         value="value"
                         label="text"
                         name="day"
                         :clearable="false"
                         :disabled="isDayDisabled"
                         @blur="$v.day.$touch()"

                     >
                     </v-select>
                     <small
                         v-if="!$v.day.check && isSubmit"
                         class="form-text text-danger"
                     >
                       {{ $t('The Validity Period Day Selection Is A Required Field') }}
                     </small>
                   </div>
                 </div>
                 <div class="row mt-1">
                   <div class="col">
                     <b-form-checkbox
                         v-model="selectNoExpired"
                         value="checked"
                     >
                       {{$t("No Time Limit")}}
                     </b-form-checkbox>
                   </div>
                 </div>
               </div>
             </b-form-group>

             <b-form-group label-for="scheduler">
                  <div class="border-bottom border-left border-right d-block border-bottom-effect pl-1 pb-1 pt-1  rounded">
                      <label for="price-type" class="d-block">
                  <p>
                    {{$t('Price Type')}}
                    <info-icon
                      v-b-modal.modal-3
                      size="1.25x"
                      class="custom-class">
                  </info-icon>
                  </p>
                  <hr>
                  </label>
                    <b-form-radio
                    v-model="priceType"
                    name="priceType"
                    value="total"
                    class="d-inline custom-radio"
                    >
                    {{$t('Total Price')}}
                    </b-form-radio>
                    <b-form-radio
                    v-model="priceType"
                    name="priceType"
                    value="unit"
                    class="d-inline mx-2 custom-radio"
                    >
                    {{$t('Unit Price')}}
                    </b-form-radio>
               
                  <small v-if="isSubmit && !$v.priceType.required" class="form-text text-danger" >{{$t("This field is required")}}</small>

                    <hr>

                  <template v-if="priceType==='total'">
               
                      <b-form-group :label="$t('Total Price')" label-for="town" class="pr-1">
                        <input class="form-control" id="price" v-model="product_data.price" type="number" placeholder="0.00" maxlength="20"/>
                        <small v-if="isSubmit && !$v.product_data.price.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
                      </b-form-group>
                      <b-form-group
                        :label="$t('Session Count')" label-for="town" class="pr-1">
                        <input class="form-control" id="session-count" v-model="product_data.sessionCount" type="number" placeholder="0" maxlength="5"/>
                        <small v-if="isSubmit && !$v.product_data.sessionCount.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
                      </b-form-group>
               
                </template>
                <template v-if="priceType==='unit'">
                  
                    <b-form-group :label="$t('Unit Price')" label-for="town" class="pr-1">
                      <input class="form-control" id="price" v-model="product_data.price" type="number" placeholder="0.00" maxlength="20"/>
                      <small v-if="isSubmit && !$v.product_data.price.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
                    </b-form-group>
                
                </template>

            </div>

              </b-form-group>

            

           </div>      
         </div>

           <!-- Geçerlilik Süresi -->


              <b-col cols="12" class="right mt-1">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  type="reset"
                  variant="outline-primary"
                  class="mr-1"
                  :to="{name:'InstructorProducts', params:{trainerID: this.product_data.person_id}}"
                >
                  {{ $t("Cancel") }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  @click="update"
                >
                  {{ $t("Save") }}
                </b-button>
              </b-col>
   
        </div>

        </b-row>
    </b-form>

        <b-modal id="modal-2" :title="$t('Geçerlilik Süresi')" ok-only header-text-variant="primary" :ok-title="$t('Ok')">
             <b-card-text>
               {{$t('Ürününüzü oluşturmadan önce ürününüzün geçerlilik süresini belirlediğinize emin olunuz.')}}
               <p class="mt-2"><b>Ay ve Gün Değerleri</b></p>
               <p>Ürününüzün geçerlilik süresini <b>ay ve gün parametreleri</b> ile belirleyebilirsiniz. <b>Ürün satın alınan tarihten itibaren seçmiş olduğunuz ay ve gün değerleri kadar ileri bir tarih için geçerlilik süresine sahip olacaktır.</b> Müşteri ürününüzü satın aldığından itibaren <b>geçerlilik süresince</b> kalan hakkını tüketebilecektir.</p>
               <p class="mt-2"><b>Süre Sınırı Olmasın</b></p>
               <p>Ürününüzün <b>sınırsız bir geçerlilik süresine</b> sahip olmasını istiyorsanız bu seçeneği işaretlemelisiniz. Süre sınırı olmayan ürünler <b>müşteri tarafından satın almış olduğu ürün aktif olduğu sürece herhangi bir tarih sonrasında</b> kalan hakkını tüketebilecektir.</p>
             </b-card-text>
           </b-modal>
           <b-modal id="modal-3" :title="$t('Fiyat Türü')" ok-only header-text-variant="primary" :ok-title="$t('Ok')">
             <b-card-text>
               {{$t('Ürününüzü oluşturmadan önce ürününüzün fiyat türünü belirlediğinize emin olunuz.')}}
               <p class="mt-2"><b>Toplam Fiyat</b></p>
               <p>Ürününüzün fiyat türünü <b>Toplam Fiyat</b> olarak belirlediğinizde ürününüz için <b>toplam fiyat ve oturum sayısı değerlerini</b> belirlemeniz gerekmektedir. Toplam fiyat türü ile ile oluşturulan ürünler <b>belirlenen oturum sayısı kadar randevu hakkı sağlar ve tüm bu oturumlar için müşterinin toplam bir fiyat üzerinden</b> ödemeyi gerçekleştirmesi gerekmektedir.</p>
               <p class="mt-2"><b>Birim Fiyat</b></p>
               <p>Ürününüzün fiyat türünü <b>Birim Fiyat</b> olarak belirlediğinizde ürününüz için <b>birim fiyat değerini</b> belirlemeniz gerekmektedir. Birim fiyat türü ile oluşturulan ürünler müşteri tarafından <b>seçilen oturum sayısınca</b> satın alınabilir. Müşterinin <b>(ürün için belirlenen birim fiyat X müşterinin seçmiş olduğu oturum sayısı)</b> tutarında ödemeyi gerçekleştirmesi gerekmektedir.</p>
             </b-card-text>
           </b-modal>

  </b-card>
  
</template>

<script>
import {
  BModal,
  VBModal,
  BAlert,
  BImg,
  BFormRadio,
  BFormSelect,
  BFormTextarea,
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
   BCardText,
    BSpinner
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import { ref } from "@vue/composition-api";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { InfoIcon } from 'vue-feather-icons'
import myUpload from 'vue-image-crop-upload/upload-2.vue';

import {required, numeric, maxLength} from "vuelidate/lib/validators";

export default {
  setup() {
    const userImg = ref("");
    const previewEl = ref(null);
    let refInputEl = ref(null);
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, function(
      base64
    ) {
      userImg.value = base64;
    });
    return {
      refInputEl,
      inputImageRenderer,
      userImg,
      previewEl,
    };
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    InfoIcon,
    BModal,
    VBModal,
    BAlert,
    BImg,
    BFormRadio,
    BFormSelect,
    BButton,
    Cleave,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BCardText,
    BSpinner,
    vSelect,
    mapGetters,
    mapActions,
    BFormTextarea,
    'my-upload': myUpload
  },
  props: {

  },

  data() {
    return {
      selectedCity:{
        city_id:"00000000-0000-0000-0000-000000000000",
        city_name: this.$t('Please choose')
      },
      selectedTown:
      {
        town_id:"00000000-0000-0000-0000-000000000000",
        town_name: this.$t('Please choose')
      },

    //product to trainer/////////////////////////////////
    product_data: {
        product_title:null,
        product_description:null,
        trainer_id:null,
        person_id: null,
        trainer_name: null,
        activity_id:null,
        //is_planned:null, // belki
        price: null,
        sessionCount: 0,
        participants_count: 10
    },
    //activites
    activities: [],
    selectedActivity:
    {
        activity_id: "00000000-0000-0000-0000-000000000000",
        name: this.$t('Please choose')
    },
    companyBranchId: null,
    //Scheduler Type
   // schedulerSelectedIsPlanned: null,
    //Price Type
    priceType: null,
    //session type
    // sessionOptions: [
    //     { value: 'PT', text: 'Single Session' },
    //     { value: 'GROUP', text: 'Unlimited Session' },
    // ],
    // sessionSelected: {
    //     value: null,
    //     text: this.$t('Please choose')
    // },
    /////////////////////////////////////////////////////
        dataloaded:false,
        selectedIndex: 0,
        isSubmit: false,

      show: false,
      params: {
        token: '123456798',
        name: 'avatar'
      },
      headers: {
        smail: '*_~'
      },
      userImg: '',
      month: {
        value: 'please',
        text: this.$t("Please Select Month")
      },
      optionsMonth: [],
      day: {
        value: 'please',
        text: this.$t("Please Select The Day")
      },
      optionsDay: [],
      selectNoExpired: false,
      isMonthDisabled: false,
      isDayDisabled: false
    };
  },
  validations:
    {
        month: {
          check: (val, vm) => {
            return vm.month.value === 'please' ? false : true;
          }
        },
        day: {
          check: (val, vm) => {
            return vm.day.value === 'please' ? false : true;
          }
        },
        product_data: {
            product_title: {
                required
            },
            product_description: {
                required
            },
            price: {
                required
            },
            sessionCount: {
              required
            },
            participants_count: {
                required
            }
        },
        selectedActivity: {
          //yazdığım custom filter
          idCheck(val,vm) {
            //lütfen seçiniz kısmında gelen default id var ise false dön yani seçim yaptırmaya zorla
            return vm.selectedActivity.activity_id === '00000000-0000-0000-0000-000000000000' ? false : true;
          }
        },
        // schedulerSelectedIsPlanned: {
        //   required
        // },
        priceType: {
          required
        }
    },

  methods: {
    ...mapActions("mockUser", ["fetchUser"]),
    removePhoto() {
      this.userImg = 'https://cdn-icons-png.flaticon.com/512/5799/5799080.png';
    },
     toggleShow() {
				this.show = !this.show;
			},
            /**s
			 * crop success
			 *
			 * [param] userImg
			 * [param] field
			 */
			cropSuccess(userImg, field){
				this.userImg = userImg;
        console.log(this.userImg);
      },
			/**
			 * upload success
			 *
			 * [param] jsonData  server api return data, already json encode
			 * [param] field
			 */
			cropUploadSuccess(jsonData, field){
				console.log('-------- upload success --------');
				console.log(jsonData);
				console.log('field: ' + field);
			},
			/**
			 * upload fail
			 *
			 * [param] status    server api return error status, like 500
			 * [param] field
			 */
			cropUploadFail(status, field){
				console.log('-------- upload fail --------');
				console.log(status);
				console.log('field: ' + field);
			},
    update(){
      //gönderilme isteği gönderildiği için true'ya çek
      this.isSubmit = true;
      if (this.month == 0 && this.day == 0 && this.selectNoExpired == false) {
        this.$functions.Messages.warning('Both Day And Month Values Cannot Be 0!');
        return false;
      }
      if(this.$v.$invalid === false){
        //eğer süre sınırı yok işaretlenir ise gün,ay değerlerini 0'a çekme doğrulaması (obj -> to int)
        if (this.selectNoExpired == 'checked') {
          this.month = 0;
          this.day = 0;
        }

        //düzenleme ekranında değerler geri objeye dönerse value al
        if(typeof this.month == 'object'){
          this.month = this.month.value
        }
        if(typeof this.day == 'object'){
          this.day = this.day.value
        }

           this.$database.StudioService.trainer_product_update(
              this.companyBranchId,
              this.$route.params.productID,
              this.product_data.trainer_id,
              this.selectedActivity.activity_id,
              this.$functions.QuoteFormat(this.product_data.product_title),
              this.$functions.QuoteFormat(this.product_data.product_description),
              this.product_data.price,
              this.product_data.sessionCount  ,
              this.userImg,
              this.product_data.participants_count,
               this.month,
               this.day
            ).then(
              res => {
                if(res.is_success){
                  this.$functions.Messages.success('Product Edited Succesful')
                  this.$router.push({name: 'InstructorProducts', params:{trainerID: this.product_data.person_id}})
                }else {
                  this.$functions.Messages.error('Action failed!')
                }
              }
            )
      }
    },

    getPersonInfo(){
      this.$database.StudioService.get_person_by_trainer_id(
        this.product_data.trainer_id,
        this.companyBranchId
      ).then(res => {
        if(res.is_success){
          this.product_data.person_id = res.result.person_id
        }
      })
    },
    getCities() {
      this.$database.DistributorService.studio_get_cities().then((res) => {
        if (!res.is_success)
              return;
            this.cities = res.result;
             // this.studio_data.city_id=res.result[0].city_id
               this.dataloaded=true;
            //  this.getTowns();
      });
    },
    getActivities(){
     this.$database.StudioService.activity_get_list(this.companyBranchId,1).then(
        (res) => {
          if (!res.is_success) return;
          this.activities = res.result;
          this.dataloaded = true;
        }
      );
    },
    getProductInfos(){
     this.$database.StudioService.get_product_by_product_id(
        this.$route.params.productID,
        this.companyBranchId
     ).then(res => {
        //response başarılı ise
        if(res.is_success){
          //Modellerer verileri al
          this.selectedActivity.activity_id = res.result.activity_id
          this.product_data.trainer_id = res.result.trainer_id
          this.product_data.product_title = res.result.title
          this.product_data.product_description = res.result.description
          this.schedulerSelectedIsPlanned =  res.result.is_planned
          this.userImg = res.result.image,
          this.product_data.participants_count = res.result.participants_count,
              this.month = res.result.expiry_month,
              this.day = res.result.expiry_day
          //eğer session sayısı 1 den büyük ise price type totaldir
          if(res.result.session > 1){
            this.priceType = 'total'
            this.product_data.price = res.result.price
            this.product_data.sessionCount = res.result.session
          } else {
            this.priceType = 'unit'
            this.product_data.price = res.result.price
          }
          this.getPersonInfo()

          // gelen ay ve gün sayı bilgisi (geçerlilik süresi) 0 ise ; süre sınırı olmasın -> checked
          if(this.month == 0 && this.day == 0){
            this.selectNoExpired = 'checked'
          } else {
            this.optionsDay.map(el => { //gün bilgisini listeden bul
              if(el.value == this.day){
                this.day = el;
              }
            })
            this.optionsMonth.map(el => { //ay bilgisini listeden bul
              if(el.value == this.month){
                this.month = el;
              }
            })
          }

        }
     })
    },
    addMonth() {
      this.optionsMonth.push({
        value: 0,
        text: '0'
      });
      for (let i = 1; i <= 36; i++) {
        this.optionsMonth.push({
          value: i,
          text: `${i} ${this.$t("Month")}`
        });
      }
    },
    addDay() {
      this.optionsDay.push({
        value: 0,
        text: '0'
      });
      for (let i = 1; i <= 31; i++) {
        this.optionsDay.push({
          value: i,
          text: `${i} ${this.$t("Day")}`
        });
      }
    },
    // async getTrainerName(){
    //   //person_id -> trainer name (name bilgisi persondan geliyor sonuçta)
    //   await this.$database.StudioService.get_trainer_name(
    //       this.$route.params.trainerID //aslında person_id
    //   ).then(res => {
    //       this.product_data.trainer_name = res.result.name  + ' ' + res.result.last_name
    //   })

    // }
  },
  watch: {
    selectNoExpired(val) {
      if (val == 'checked') {
        this.day = {
          value: 0,
          text: this.$t("No Day Limit")
        };
        this.month = {
          value: 0,
          text: this.$t("No Month Limit")
        };
        this.isMonthDisabled = true;
        this.isDayDisabled = true;
      } else {
        this.isMonthDisabled = false;
        this.isDayDisabled = false;
        this.day = {
          value: 'please',
          text: this.$t('Please choose')
        };
        this.month = {
          value: 'please',
          text: this.$t('Please choose')
        };
      }
    },

    //2.kontrol mekanizmam - asenkron inputları izleyerek şu an 20 (max) karakter ile sınırlandırıyorum
    'studio_data.phone_number'(val){
      if(this.studio_data.phone_number.length > 20){
        this.studio_data.phone_number = val.slice(0,20)
      }
    },
    'studio_data.tax_code'(val){
      if(this.studio_data.tax_code.length > 20){
        this.studio_data.tax_code = val.slice(0,20)
      }
    },
    'product_data.price'(val){
        if(this.product_data.price.length > 20){
            this.product_data.price = val.slice(0,20)
        }
    },
    'product_data.sessionCount'(val){
        if(this.product_data.sessionCount.length > 5){
            this.product_data.sessionCount = val.slice(0,5);
        }
    },
    priceType(val){
      if(val === 'unit'){
        this.product_data.sessionCount = 1;
      }
    },

    selectedActivity(val){
        if(this.dataloaded == true)
        {
            this.selectedActivity.activity_id = val;
        }
    },

    //sp_trainer_product_id
  },
  created()
  {
    this.addMonth();
    this.addDay();
    this.companyBranchId = this.$store.getters["auth/userInfo"].company_branch_id
    this.getPersonInfo()
    // this.getTrainerName();
    this.getActivities();
    this.userImg = 'https://cdn-icons-png.flaticon.com/512/5799/5799080.png';
    this.getProductInfos();
  },
};
</script>

<!-- stillendirme -->
<style lang="scss" src="@/styles/scss/editProductScoped.scss" scoped></style>
